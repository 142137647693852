/* LivenessComponent.css */

/* General styles for the container */
.liveness-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #f0f0f0; /* Light gray background */
  padding: 20px;
  box-sizing: border-box;
}

/* Loader centering */
.liveness-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Flex container styles */
.liveness-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* FaceLivenessDetector styles */
.liveness-detector {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff; /* White background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 20px;
}

/* Result display styles */
.liveness-result {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center;
}

/* LivenessComponent.css */
.alert-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  z-index: 1000;
  text-align: center;
}

.alert-centered button {
  margin-top: 10px;
}

.amplify-liveness-toast__message div {
  font-size: 22px;
  margin-top: -10px;
}

.amplify-liveness-cancel-container button {
  display: none;
}